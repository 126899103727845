.headwait {
  font-weight: bold;
  padding-left: 5px;
  padding-top: 2px;
}
.menudispaly {
  display: flex;
  width: 30%;
  justify-content: space-between;
  margin-top: 5px;
}
.menuwait {
  font-weight: bold;
}
.menuDetail {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
}
.menufontwait {
  font-weight: bold;
}
.pagecenter {
  text-align: center;
  border: 1px solid;
  margin: 0px 40% 0px 40%;
  border-radius: 8px;
}
.butttonstyle {
  border: 1px solid;
  border-radius: 8px;
  color: white;
  background-color: red;
}
.buttonmargin {
  margin-top: 10px;
}
.butttonsize {
  margin: 0px 10% 0px 10%;
}
.pagestylepad {
  padding: 5px 0px 10px 0px;
}
.digintrac {
  text-align: center;
}
.editcomand {
  border: 2px solid gray;
  border-radius: 5px;
  padding-left: 4px;
  padding-right: 4px;
}
.setunderline {
  text-decoration: underline;
}
.editarrowlogo {
  width: 16px;
  height: 16px;
  padding-top: 4px;
}
.aarowsetdisplay {
  display: flex;
  justify-content: space-between;
}

.boldtype {
  font-weight: bold;
}
.editpagecontent {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin-left: 15%;
}

.margin10 {
  margin-top: 6px;
}
.setfrootimg {
  width: 40px;
  height: 40px;
}
.setbodyborder {
  box-shadow: 5px 5px 5px 15px rgba(0, 0, 255, 0.2);
  border: 1px solid;
  padding: 10px 2px 10px 2px;
  border-radius: 3px;
  margin-top: 20px;
}
.sethederline {
  font-weight: bold;
  font-size: larger;
}
.setproduct {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin-left: 15px;
}
.settotal {
  display: flex;
}
.margin5 {
  margin-right: 40px;
}
.produtcontener {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin-left: 15px;
}
.setmargin15 {
  margin-left: 75%;
}
.displayset {
  display: flex;
  justify-content: space-between;
  width: 97%;
}
.setmargin6 {
  margin-right: 50px;
}

.headcreat {
  font-weight: bold;
  text-align: center;
  font-size: larger;
}
.digainflex {
  width: 50%;
  display: flex;
  justify-content: space-between;
}
.buttoncenters {
  text-align: center;
  border: 1px solid;

  border-radius: 8px;
  margin-top: 5px;
  background-color: red;
  color: white;
  cursor: pointer;
}
.borderdiv {
  border: 1px solid;
  border-radius: 8px;
  padding: 10px 0px 15px 0px;
  margin: 0px 30% 0px 30%;
}
.dilogbox {
  width: 100%;

  color: black;
}

.contentmargin {
  margin-top: 15px;
  margin-bottom: 15px;
}
.pagrdistans {
  margin-top: 8px;
}
._2MJ9D {
  background-color: white;
  width: 50%;
  border-radius: 12px;
  margin-left: 25% !important;
}

._br_-q {
  width: 100%;

  background: none !important;
  margin-left: 25% !important;
}
._2RHFL {
  font-size: 25px !important;
}
.boxdigain {
  margin-left: 25px !important;
}
._2RHFL {
  padding: 0px 0px 0px 0px !important;
}
.arrowimg {
  width: 10px;
  height: 10px;
}
.setimages {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.setdiloag {
  width: 60%;
  border: 1px solid gray;
  border-radius: 8px;
  box-shadow: 12px 12px 2px 1px rgb(202, 202, 202);
}
.setboxlayout {
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.topdiv {
  display: flex;
  justify-content: space-between;
  width: 40%;
}
.setspace {
  width: 400px;
  display: flex;
  justify-content: space-between;
}
.setimaglogo {
  width: 80px;
  height: 80px;
}
.setboxdiv {
  display: flex;
  justify-content: space-between;
}
.setmargin10 {
  margin-top: -8%;
}
.setflex {
  display: flex;
  justify-content: space-between;
}
.setbutton {
  border: 1px solid;
  margin-bottom: 55px;
  padding: 4px 15px 4px 15px;
  border-radius: 12px;
}
.settablename {
  font-weight: bolder;
  font-size: large;
}
.hedname {
  font-weight: bolder;
}
.setclosebutton {
  width: 20px;
  height: 20px;
}
.setposition {
  position: absolute;
  right: 15px;
  top: 10px;
}
.setdateborder {
  border: 1px rgb(160, 158, 158) solid;
  border-radius: 8px;
  padding: 2px 16px 0px 16px;
}
.pagebox {
  width: 25%;
  border-radius: 15px;
  border: 1px solid;
  box-shadow: 12px 12px 2px 1px rgb(75, 74, 74);
}
.setfooterdisplay {
  display: flex;
  justify-content: space-between;
  width: 15%;
  margin-left: 82%;
  margin-bottom: 10px;
}
.setfooterborder {
  border: 1px solid;
  padding: 4px 4px 4px 4px;
}
.headdigin {
  font-weight: bold;
  padding-left: 15px;
  padding-top: 10px;
}
.setfaceimg {
  display: flex;
}
.setfaceiconimg {
  width: 30px;
  height: 30px;
}

.setfelex {
  display: flex;
  justify-content: space-between;
}
.setsearchbar {
  border: 1px solid rgb(187, 184, 184);
  border-radius: 8px;
  padding: 0px 2px 0px 35px;
  margin-left: 27%;
}
.setdateimg {
  width: 13px;
  height: 13px;
}

.editsaerch {
  border: 1px solid;
  border-radius: 15px;
  margin-bottom: 25px;
}
.firstdigain {
  display: flex;
}
.spacetimedate {
  margin-left: 20px;
}
.firstone {
  display: flex;
  justify-content: space-between;
}
.creatsearch {
  border: 1px solid;
  border-radius: 8px;
}

.backcolordigain {
  background-color: rgb(223, 219, 219);
}

.AllOrderContent {
  display: flex;
  justify-content: space-between;

  padding: 5px 10px;
}

.tableheading {
  background-color: darkgray;
}
.allcontentdark {
  background-color: lightgray;
}
.padding2 {
  padding: 2px;
}

.hidetext {
  cursor: pointer;
}
.viewMoreDialog {
  margin-right: 0%;
  height: 16%;
  border: white;
  border-radius: 20px;
}
.margin0 {
  margin-top: 0%;
  margin-bottom: 5px;
  font-weight: bold;
  cursor: pointer;
}
.marginbuttom {
  margin-bottom: 0%;
  margin-top: 5px;
  font-weight: bold;
  cursor: pointer;
}

.setsearchimg {
  width: 20px;
  height: 20px;
}
.setsearchbar {
  display: flex;
  justify-content: space-between;
}
.setright5 {
  margin-left: 5px;
}
.padding5 {
  padding: 5px 20px 10px 20px;
}
.marginin {
  margin-left: -30px;
}
/* navbar css */
.HeaderMainDiv {
  width: 100%;
  height: 64px;
  position: relative;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  display: flex;
  justify-content: space-between;
}
.ProfileName {
  padding-right: 20px;
}
.leftbarheading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.material-icons {
  color: #26ae61;
  padding-right: 7px;
}

.activeLeft {
  background-color: #26ae61;
  min-height: 30px !important;
}
.inactiveLeft {
  min-height: 30px !important;
}

.leftbar {
  height: calc(100vh - 95px);
  overflow-y: scroll;
}

.leftbar .MuiTab-wrapper {
  flex-direction: row !important;
  justify-content: left !important;
}

.leftbar .MuiTab-labelIcon {
  min-height: 0px;
  font-size: 12px !important;
}
.LeftIConPadding {
  margin-bottom: 0px !important;
}
.addOrder {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999;
}
