@media only screen and (max-width: 600px) {
  .hocHeaderPadding {
    padding-top: 60px !important;
  }
}
.hocHeaderPadding {
  padding-top: 70px;
}
.normal_header_padding {
  padding-right: 3% !important;
  padding-left: 3% !important;
}
.header_desktop_view {
  background-color: white !important;
}

.candidate_header_grow {
  flex-grow: 1;
}
.ProfileName {
  padding-right: 20px;
  color: black;
}
.sieBarWidthFix {
  position: relative;
  border-right: 1px solid rgb(239, 239, 239);
  border-top-color: rgb(239, 239, 239);
  border-bottom-color: rgb(239, 239, 239);
  border-left-color: rgb(239, 239, 239);
  transition: width 0s ease 0s, left 0s ease 0s, right 0s ease 0s,
    all 300ms ease 0s;
  width: 250px;
  min-width: 250px;
  color: rgb(96, 116, 137);
}
.sideBarWithHide {
  position: relative;
  border-right: 1px solid rgb(239, 239, 239);
  border-top-color: rgb(239, 239, 239);
  border-bottom-color: rgb(239, 239, 239);
  border-left-color: rgb(239, 239, 239);
  transition: width 0s ease 0s, left 0s ease 0s, right 0s ease 0s,
    all 300ms ease 0s;
  width: 80px;
  min-width: 80px;
  color: rgb(96, 116, 137);
}
.sidebarMainPostion {
  position: fixed !important;
  height: 90%;
  overflow: auto;
  z-index: 999 !important;
}
.LeftIConPadding {
  padding-right: 0px !important;
}
.subscripionHeaderBgColor {
  background-color: yellow;
  color: red;
  text-align: center;
}
.dashboardCalenderflex {
  display: flex;
  justify-content: flex-end;
}
.buttonPadding {
  padding: 0px 5px;
  cursor: pointer;
  color: black;
}
.dashboardCloseIcon {
  float: right;
  cursor: pointer;
  color: black;
}
