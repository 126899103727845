.inputParentHeight {
  height: 50px;
  margin: 5px 10px;
  position: relative;
}
.inputLabelHeight {
  color: #757575;
}
.commonInput {
  height: 30px;
  outline: none !important;
  width: 100%;
  padding-left: 10px;
}
.inputPostion {
  position: absolute;
  bottom: 0;
  width: 100%;
}

/* ==========================================================================
   Buttons
   ========================================================================== */
.button {
  font-size: 22px;
  /* line-height: 14px; */
  display: inline-block;
  position: relative;
  outline: none !important;
  /* background: $preset; */
  color: #fff;
  cursor: pointer;
  border: none;
  text-align: center;
  overflow: hidden;
  padding: 11px 12px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-radius: 4px;
  z-index: 1;

  /* &:hover {
      box-shadow: 0px 7px 9px rgba(96, 94, 94, 0.17);
    } */
}

.button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 4px;
  opacity: 0;
  transform: scale(0, 0);
  z-index: -1;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.button:hover::before {
  background-color: rgba(255, 255, 255, 0.2);
  opacity: 1;
  transform: scale(1, 1);
}
.btn {
  font-size: 14px;
  padding: 10px 30px;
  border-radius: 30px;
  letter-spacing: 1px;
  font-weight: 400;
  color: #fff;
  border: none;
  /* text-transform: uppercase; */
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
}

.btn:focus,
.btn:active {
  box-shadow: none;
  outline: none;
}

.btn-common {
  background: #26ae61;
  position: relative;
  z-index: 1;
  margin: 5px;
  cursor: pointer;
}
.btn-common:hover {
  color: #fff;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.15), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.btn-common::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 30px;
  opacity: 0;
  transform: scale(0, 0);
  z-index: -1;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.btn-common:hover::before {
  background-color: rgba(255, 255, 255, 0.2);
  opacity: 1;
  transform: scale(1, 1);
}
.button-group .button {
  font-size: 14px;
  line-height: 14px;
  display: inline-block;
  position: relative;
  outline: none !important;

  color: #fff;
  border: none;
  text-align: center;
  overflow: hidden;
  padding: 9px 21px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 30px;
  z-index: 1;
  margin: 4px;
}
.smallButton {
  padding: 5px 20px !important;
}
/* ==========================================================================
   Buttons end
   ===================== ===================================================== */
.dailogContent {
  height: calc(75vh - 10px);
  overflow: auto;
}
