/* a {
  color: black !important;
} */

.leftcontent {
  padding: 10px;
  display: flex;
  justify-items: center;
  cursor: pointer;
}
.dispalyFlex {
  display: flex
}
  
.leftcontent:focus{
  color: blue; 
}
.leftcontent .material-icons:focus{
  color: blue !important; 
}

.leftbarlogout {
  position: absolute;
  display: flex;
  bottom: 0;
  left: 20px;
  cursor: pointer;
}
