@media only screen and (max-width: 600px) {
  .Internal_div {
    width: 70% !important;
  }
}

.LoginmainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.Internal_div {
  width: 30%;
  padding: 30px;
  box-shadow: 0 0 14px hsl(0deg 0% 75% / 24%);
}
.loginTitleCenter {
  text-align: center;
  font-weight: bold;
}

.d-relative {
  position: relative;
}
.seteyeicon {
  position: absolute;
  top: 25px;
  right: 10px;
  cursor: pointer;
}
.validcolor {
  color: red;
}
